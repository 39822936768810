body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* NAVBAR STYLING */

.nav-link {
  color: #402f1c !important;
  font-size: 20px !important;
}
.nav-link:hover {
  text-shadow: 0 0 8px rgb(175, 174, 174) !important;
}
.navbar-collapse {
  flex-grow: 0 !important;
}

/* FONTAWESOME STYLING */

.highlight:hover {
  color: rgb(175, 174, 174);
}
